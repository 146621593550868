import React from "react"
import Layout from '../components/layout';
import Box  from "@material-ui/core/Box";
import { Link } from "gatsby-theme-material-ui";
import Button  from "@material-ui/core/Button";
import {navigate} from 'gatsby';

const VerkkokauppaPage = props => {
  const onBtnClick = (e) => {
    navigate("https://docs.google.com/spreadsheets/d/1nwaEQek2ijFEfFvw5_e2h4OgbOMrlJyFXaIP3a4G4MY/edit?usp=sharing")
  }
    return (
    <Box justifyContent="center" alignItems="center" style={{padding:'50px 0', textAlign:'center'}}>
        <h1>Verkkokaupat, joilla on puhelinnumero etusivulla</h1>
        <Button variant="contained" size="large" color="primary" onClick={onBtnClick}>Katso listatut verkkokaupat</Button>
    </Box>
    )
    //return <div>TEST</div>
}
export default function Verkkokauppa(props) {
  const pagemeta={
    title: 'Verkkokaupat, joilla on puhelinnumero etusivulla | No No No',
    description: 'Katso listatut verkkokaupat'
  }
  return (    
    <Layout children={<VerkkokauppaPage />} pagemeta={pagemeta} />
  );
};
